@import "../../scss/variables";

@mixin disabled {
  color: $grayLight;
  cursor: not-allowed;
  pointer-events: none;
}

@mixin loading {
  color: $grayLight;
  cursor: not-allowed;
  pointer-events: none;

  &:before {
    content: '';
    display: block;
    background-image: url(../../assets/img/loader.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 16px;
    width: 16px;
    position: relative;
    background-size: contain;
    margin-right: 10px;
    filter: grayscale(1);
    transform: rotate(0deg);
    animation: rotate .7s linear 0s infinite
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

@mixin btn {
  position: relative;
  line-height: 1;
  color: $orange;
  font-weight: 500;
  text-align: center;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: $orangeLight;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}

@mixin innerImages {
  svg {
    fill: currentColor;
  }
  img:not(:last-child),
  svg:not(:last-child) {
    margin-right: 10px;
  }
}

.btn {
  @include btn;
  @include innerImages;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: $orangeLight;
  &.disabled {
    @include disabled;
    background-color: $grayLightest !important;
    color: $gray !important;
  }
  &.loading {
    @include loading;
    background-color: $grayLightest !important;
    color: $gray !important;
  }
  @media (min-width: 990px) {
    &:hover {
      background-color: darken($orangeLight, 5%);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    }
    &:focus,
    &:active {
      background-color: darken($orangeLight, 5%);
      color: $orangeActive;
      fill: $orangeActive;
      box-shadow: none;
    }
  }
  &--medium {
    font-size: 16px !important;
  }
  &--small {
    font-size: 16px !important;
    padding: 8px 12px !important;
  }

  &-outline {
    @include btn;
    @include innerImages;
    //display: inline-block;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    border: 1px solid $orange;
    background-color: transparent;
    @media (min-width: 990px) {
      &:hover,
      &:active,
      &:focus {
        border: 1px solid darken($orange, 10%);
        color: darken($orange, 10%);
        fill: darken($orange, 10%);
      }
    }
    &.disabled {
      border-color: $grayLight;
      color: $gray !important;
      @include disabled;
    }
    &.loading {
      @include loading;
      border-color: $grayLight;
      color: $gray !important;
    }
  }

  &-text {
    @extend .btn-outline;
    border: none;
    padding: 0;
    svg {
      fill: currentColor;
    }
    img:not(:last-child),
    svg:not(:last-child) {
      margin-right: 10px;
    }
    @media (min-width: 990px) {
      &:hover,
      &:active,
      &:focus {
        border: none;
        color: darken($orange, 10%);
        fill: darken($orange, 10%);
      }
    }
    .disabled {
      border: none;
      color: $gray !important;
      @include disabled;
    }
    .disabled {
      border: none;
      color: $gray !important;
      @include loading;
    }
  }
}


//@mixin disabled {
//  color: $grayLight;
//  cursor: not-allowed;
//  pointer-events: none;
//}
//
//.btn {
//  position: relative;
//  display: inline-flex;
//  line-height: 1.5;
//  color: $orange;
//  text-align: center;
//  align-items: center;
//  justify-content: center;
//  padding: 12px 24px;
//  max-height: 44px;
//  min-height: 32px;
//  font-size: 16px;
//  font-weight: 500;
//  border-radius: 4px;
//  background-color: $orangeLight;
//  cursor: pointer;
//  transition: all 0.2s ease 0s;
//  & svg {
//    fill: currentColor;
//  }
//  & img:not(:last-child), & svg:not(:last-child) {
//    margin-right: 10px;
//  }
//  &.large {
//    font-size: 20px;
//  }
//  &.small {
//    font-size: 16px;
//    padding: 4px 12px;
//  }
//}
//
//.outline {
//  border: 1px solid $orange;
//  color: $orange;
//  background: transparent;
//  &:hover, &:active, &:focus {
//    border: 1px solid darken($orange, 10%);
//    color: darken($orange, 10%);
//  }
//  &.disabled{
//    border: 1px solid $grayLight;
//    @include disabled;
//  }
//}
//
//.text {
//  @extend .outline;
//  border: none;
//  &:hover, &:active, &:focus {
//    border: none;
//    color: darken($orange, 10%);
//  }
//  &.disabled{
//    border: none;
//    @include disabled
//  }
//}
//
//.primary {
//  color: $orange;
//  background-color: $orangeLight;
//  &.disabled{
//    @include disabled;
//    background-color: $grayLightest;
//    color: $gray;
//  }
//  &:hover {
//    background-color: darken($orangeLight, 5%);
//    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
//  }
//  &:focus, &:active {
//    background-color: darken($orangeLight, 5%);
//    color: $orangeActive;
//    box-shadow: none;
//  }
//  &.small {
//    font-size: 16px;
//    padding: 4px 12px;
//    &:hover {
//      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
//    }
//  }
//}
